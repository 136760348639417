@import '../../node_modules/bootstrap/less/bootstrap.less';
@import './variables.less';
@import './mixins.less';
// Global Components
html {
    height: 100%;
}

body {
    overflow-x: hidden;
    .body-font;
    background-color: @gray-lighter;
    color: @gray;
    height: 100%;
    &.loading {
        position: relative;
        background-color: @theme-secondary;
        &:after {
            background-color: none;
            background: none;
            background-size: 100% 100%;
            top: 50%;
            left: 50%;
            margin-left: -98px;
            margin-top: -100px;
            height: 50px;
            width: 196px;
        }
        .sk-fading-circle {
            display: inline-block;
            position: absolute;
            margin-top: -20px;
            margin-left: -15px;
            top: 50%;
            left: 50%;
        }
    }
}

.text-muted {
    color: @gray-light;
}

.text-primary {
    color: @theme-primary;
}

*:after {
    background-color: transparent;
}

.loading {
    position: relative;
    min-height: 150px;
    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(235, 238, 243, 0.8);
        background-image: url('../img/loading.gif');
        background-position: center center;
        background-size: 80px 80px;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        -webkit-animation: display-none-transition 1s both;
        animation: display-none-transition 1s both;
        transition: background-color 0.5s;
    }
    &.white {
        &:after {
            background-color: rgba(255, 255, 255, 0.8);
            background-image: url('../img/loading-white-bg.gif');
        }
    }
}

p {
    font-size: 14px;
    line-height: 1.75;
}

p.large {
    font-size: 16px;
}

a,
a:hover,
a:focus,
a:active,
a.active {
    outline: none;
}

a {
    color: @theme-primary;
    cursor: pointer;
}

a:hover,
a:focus,
a:active,
a.active {
    color: darken(@theme-primary, 10%);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    .heading-font;
    font-weight: 600;
}

h2 {
    text-align: center;
    font-size: 20px;
    color: @gray;
    position: relative;
    margin: 30px 0 10px 0;
    &:before {
        position: absolute;
        content: '';
        height: 1px;
        width: 100%;
        top: 50%;
        left: 0;
        background-color: #ddd;
        z-index: 2;
    }
    span {
        position: relative;
        font-weight: 200;
        padding: 0 20px;
        background-color: @gray-lighter;
        z-index: 3;
        text-transform: uppercase;
        color: @gray-mid;
    }
    @media (max-width: 768px) {
        font-size: 16px;
    }
}

h3 {
    font-size: 18px;
    color: @gray;
    margin-bottom: 20px;
    @media (max-width: 768px) {
        font-size: 14px;
    }
}

.btn {
    border-radius: 0;
}

.fancybox {
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    color: @gray;
    padding: 45px;
    outline: 1px dashed @gray-mid;
    outline-offset: -15px;
    h3 {
        margin-top: 0;
        font-size: 28px;
        font-weight: 300;
    }
    .btn-xl {
        font-size: 14px;
        padding: 7px 15px;
        margin-top: 10px;
        box-shadow: 0 3px 0 0 darken(@theme-primary, 10%);
        &:hover {
            box-shadow: 0 3px 0 0 darken(@theme-primary, 10%);
        }
    }
}

.box {
    position: relative;
    background-color: #fff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
    padding: 30px;
    section {
        margin: 0 -30px 0 -30px;
        padding: 30px;
        border-bottom: 1px solid #ddd;
        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
    > h1 {
        font-size: 28px;
        font-weight: 400;
        color: @theme-secondary;
        background-color: @gray-lighter; //padding: 0 0 30px 0;
        border-bottom: 1px solid #ddd;
        margin: -30px -30px 30px -30px;
        padding: 20px 30px;
    }
    > h1 + section {
        margin-top: -30px;
    }
    h2 {
        margin: 0 0 30px 0;
        span {
            background-color: #fff;
        }
    }
    .call-to-action {
        font-size: 1.5em;
        margin-bottom: 20px;
    }
    a:not(.btn) {
        text-decoration: underline;
        &.fa-stack {
            transition: all 0.3s ease;
            font-size: 40px;
            color: #ddd;
            @media (max-width: 768px) {
                font-size: 30px;
            }
            &:hover {
                //color: darken(@theme-primary, 5%);
            }
        }
        &.selected {
            color: @theme-primary;
        }
        &.inline {
            color: #000;
            text-decoration: none;
        }
    }
    .image {
        position: relative;
        width: 100%;
        height: 200px;
        background: @gray-light;
        background-size: cover;
        background-position: center center; //border-bottom: 1px solid @gray-mid;
        background-image: url('../img/herausforderungen-loesungen.jpg');
        &.about-1 {
            background-image: url('../img/information-inspiration.jpg');
        }
        &.about-2 {
            background-image: url('../img/herausforderungen-loesungen.jpg');
        }
        &.about-3 {
            background-image: url('../img/einkauf-gastrobedarf.jpg');
        }
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.05);
        }
    }
    &.coming-soon {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 117px;
            height: 117px;
            background-image: url('../img/comingsoon-badge.png');
            background-size: 100% 100%;
            z-index: 2;
        }
        &.solution-detail:before {
            background-image: url('../img/comingsoon-badge-right.png');
            left: inherit;
            right: 0;
        }
    }
    &.lr-split {
        display: table;
        width: 100%;
        padding: 0;
        min-height: 242px;
        .split-left {
            display: table-cell;
            overflow: hidden;
            width: 60%;
            position: relative;
            background-image: url('../img/information-inspiration.jpg');
            background-size: cover;
            background-position: center center;
            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.05);
            }
        }
        .split-right {
            padding: 30px;
            display: table-cell;
            width: 40%;
        }
        @media (max-width: 992px) {
            .split-left {
                width: 50%;
            }
            .split-right {
                width: 50%;
            }
        }
        @media (max-width: 768px) {
            display: block;
            .split-left {
                width: 100%;
                height: 200px;
                display: block;
            }
            .split-right {
                width: 100%;
                display: block;
            }
        }
    }
    &.article {
        margin-top: 20px;
        .image {
            position: relative;
            margin-bottom: 20px;
            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.05);
            }
        }
        .topic {
            .heading-font;
            font-size: 16px;
            height: 22px;
            overflow: hidden;
            color: @gray-mid;
            white-space: nowrap;
            text-overflow: ellipsis;
            a {
                color: inherit;
                text-decoration: none;
                &:hover {
                    //text-decoration: underline;
                }
            }
        }
        h3 {
            font-size: 22px;
            font-weight: 400;
        }
        p {
            line-height: 1.5;
        }
        .btn-xl {
            font-size: 14px;
            padding: 7px 15px;
            margin-top: 10px;
            box-shadow: 0 3px 0 0 darken(@theme-primary, 10%);
        }
        @media (min-width: 992px) {
            .col-md-4 & {
                .image {
                    margin: -30px -30px 30px -30px;
                    width: auto;
                }
            }
        }
    }
    &.article-detail {
        margin-top: -220px;
        position: relative;
        .article-content {
            .category {
                font-weight: 600;
            }
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                clear: both;
            }
            h1 {
                margin: 20px 0; //font-weight: 200;
                font-size: 48px;
                letter-spacing: -1px;
            }
            h2 {
                font-size: 30px;
                text-align: left;
                margin: 30px 0 20px 0;
                &:before {
                    display: none;
                }
            }
            h3 {
                font-size: 22px;
            }
            strong {
                color: #444;
            }
            em {
                font-family: 'Roboto Slab';
            }
            img {
                max-width: 100%;
                margin-bottom: 20px;
                &.main-image {
                    width: 100%;
                    margin: 20px 0;
                }
                &.alignleft {
                    float: left;
                    margin-right: 1.5em;
                }
            }
            p.intro {
                color: @gray-mid;
                .serif-font;
                font-style: italic;
                font-size: 16px;
            }
            .wp-caption-text {
                color: @gray-mid;
            }
            ul {
                li {
                    margin-bottom: 10px;
                }
            }
        }
        .side-content {
            min-height: 600px;
        }
        .sidebox {
            padding: 20px;
            background-color: #f1f1f1;
            margin-bottom: 20px;
            &.light {
                border: 1px solid #ddd;
                background-color: transparent;
                h3 span {
                    background-color: #fff;
                }
            }
            &.lighter {
                padding: 0;
                background-color: transparent;
                h3 span {
                    background-color: #fff;
                }
            }
            h3 {
                margin-top: 5px;
                text-align: center;
                font-size: 14px;
                color: @gray;
                position: relative;
                margin: 5px 0 20px 0;
                &:before {
                    position: absolute;
                    content: '';
                    height: 1px;
                    width: 100%;
                    top: 50%;
                    left: 0;
                    background-color: #ddd;
                    z-index: 2;
                }
                span {
                    position: relative;
                    font-weight: 200;
                    padding: 0 10px;
                    background-color: #f1f1f1;
                    z-index: 3;
                    color: @gray-mid;
                }
                @media (max-width: 768px) {
                    font-size: 16px;
                }
            }
            .media {
                border-bottom: 1px solid #ddd;
                &:last-of-type {
                    border-bottom: 0;
                }
                .media-left {
                    width: 65px;
                }
                .media-object {
                    max-width: 64px;
                    width: 100%;
                    height: 80px;
                    overflow: hidden;
                }
                .media-body {
                    img {
                        width: 100%;
                        margin-bottom: 10px;
                    }
                    .category {
                        font-weight: 600;
                        margin-bottom: 10px;
                    }
                    h4 {
                        margin: 0 0 10px 0;
                    }
                    p {
                        line-height: 1.5;
                    }
                }
            }
            .product {
                margin-bottom: 15px;
                img {
                    max-width: 100%;
                    max-height: 80px;
                }
                h4 {
                    font-size: 16px;
                    font-family: 'Roboto';
                    word-wrap: break-word;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
                .prices {
                    margin-top: 5px;
                    .original-price {
                        display: inline-block;
                        min-width: 55px;
                        text-decoration: line-through;
                    }
                    .price {
                        color: #000;
                        font-weight: 700;
                    }
                }
            }
        }
        @media (max-width: 768px) {
            h1 {
                font-size: 30px !important;
            }
        }
    }
}

a.image-link {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

#registerForm {
    border-top: 1px solid #ddd;
    margin: 30px 0 0 0;
    padding: 30px 0 0 0;
    label {
        display: block;
        font-size: 18px;
        font-weight: 200;
        margin-bottom: 30px;
    }
    .radiobuttons {
        text-align: left;
        display: inline-block;
        margin-bottom: 20px;
    }
    input[type='radio'] {
        display: none;
        & + label {
            cursor: pointer;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            vertical-align: middle;
            display: inline-block;
            margin-bottom: 5px;
            user-select: none;
        }
    }
    input[type='radio'] + label .fa {
        color: @theme-primary;
        margin-right: 10px;
        font-size: 20px;
    }
    input[type='radio'] + label .fa-dot-circle-o {
        display: none;
    }
    input[type='radio']:checked + label .fa-dot-circle-o {
        display: inline;
    }
    input[type='radio']:checked + label .fa-circle-o {
        display: none;
    }
    input[type='checkbox'] {
        display: none;
    }
    .checkbox label {
        padding-left: 0;
        user-select: none;
    }
    input[type='checkbox'] ~ .fa {
        color: @theme-primary;
        margin-right: 10px;
        font-size: 20px; //width: 30px;
        display: inline-block;
    }
    input[type='checkbox'] ~ .fa-check-square,
    input[type='checkbox'] ~ .fa-check-square-o {
        display: none;
    }
    input[type='checkbox']:checked ~ .fa-check-square,
    input[type='checkbox']:checked ~ .fa-check-square-o {
        display: inline;
    }
    input[type='checkbox']:checked ~ .fa-square-o {
        display: none;
    }
    .btn-xl {
        margin: 30px 0 0 0;
    }
}

.img-centered {
    margin: 0 auto;
}

.bg-light-gray {
    background-color: @gray-lighter;
}

.bg-darkest-gray {
    background-color: @gray-darker;
}

// Restyled Primary Buttons
.btn-primary {
    .button-variant(white;
    @theme-primary;
    @theme-primary);
    .heading-font;
    font-weight: 700;
    border: 0;
}

.btn-selected {
    border-color: darkgreen !important;
    color: darkgreen !important;
}

.btn-xl {
    .button-variant(white;
    @theme-primary;
    @theme-primary);
    .heading-font;
    font-weight: 400;
    border-radius: 0;
    font-size: 18px;
    padding: 15px 30px;
    border: 0;
    position: relative;
    z-index: 3;
    box-shadow: 0 5px 0 0 darken(@theme-primary, 10%);
    &:hover {
        background-color: darken(@theme-primary, 5%);
        box-shadow: 0 5px 0 0 darken(@theme-primary, 15%);
    }
    &.btn-default {
        background-color: #ccc;
        color: #555;
        box-shadow: 0 5px 0 0 darken(#ccc, 10%);
        &:hover {
            background-color: darken(#ccc, 5%);
            box-shadow: 0 5px 0 0 darken(#ccc, 15%);
        }
    }
    @media (max-width: 768px) {
        font-size: 14px;
        padding: 10px 20px;
    }
}

// Custom Navigation Bar
.navbar-custom {
    background: rgba(0, 23, 55, 1);
    border-color: transparent;
    padding: 0 0 0 @padding-large-base;
    font-size: @font-size-small;
    .navbar-brand {
        color: transparent;
        height: 40px;
        width: 200px;
        padding: 0;
        margin-left: 0 !important;
        margin-top: 9px;
        background-size: contain;
        background-image: url('/frontend/img/logo-gastivo.png');
        background-repeat: no-repeat;
        background-position: left center;
        @media (max-width: @screen-sm) {
            //height: 35px !important;
            width: 120px;
            margin-top: (@navbar-height - 38) / 2;
        }
    }
    .navbar-collapse {
        border-color: fade(white, 2%);
    }
    .navbar-toggle {
        background-color: transparent;
        border: 0;
        .body-font;
        color: white;
        font-size: @font-size-base;
        margin-left: @padding-large-base;
        padding: 5px 0;
        @media (max-width: @screen-sm) {
            margin-left: 5px;
        }
        .fa {
            font-size: 24px;
        }
        &:hover,
        &:focus {
            background-color: transparent;
        }
        &.menu-link {
            background-color: transparent;
            border: transparent;
            margin-right: 5px;
            font-size: @font-size-large;
        }
    }
    .nav {
        li {
            a {
                .body-font;
                font-size: @font-size-small;
                font-weight: 300;
                letter-spacing: 1px;
                color: white;
                &:hover {
                    color: @theme-primary;
                    outline: none;
                }
                &:focus {
                    color: white;
                    outline: none;
                }
                &.active {
                    text-decoration: underline;
                }
                .fa-2x {
                    margin-top: -3px;
                }
            }
            &.dropdown {
                &.active {
                    text-decoration: underline;
                    color: #fff;
                    &:hover {
                        text-decoration: none;
                    }
                }
                @media (min-width: @screen-xs) {
                    &.open {
                        a {
                            color: @theme-primary;
                            background-color: transparent !important;
                        }
                    }
                    .dropdown-menu {
                        margin-right: @padding-large-base;
                        border-radius: 0;
                        &.account {
                            width: 250px;
                        }
                        li {
                            color: @gray-dark;
                            &.dropdown-header {
                                .serif-font;
                                font-weight: 400;
                                color: #888;
                                padding-bottom: 7px;
                                white-space: normal;
                                &.user-info {
                                    padding-top: 7px;
                                    padding-bottom: 7px;
                                }
                            }
                            &.logout {
                                padding-bottom: 5px;
                            }
                            &.divider {
                                background-color: @separator-color;
                            }
                            a {
                                letter-spacing: 0;
                                color: @gray-dark;
                                &:hover {
                                    color: @theme-primary;
                                }
                            }
                            .fa {
                                margin-right: @padding-large-base;
                            }
                        }
                    }
                }
                @media (max-width: @screen-xs) {
                    &.open {
                        a {
                            color: white;
                            background-color: transparent !important;
                        }
                    }
                    .dropdown-menu {
                        border-radius: 0;
                        li {
                            font-size: 14px;
                            &.dropdown-header.user-info {
                                padding-top: 6px;
                                .serif-font;
                            }
                            a {
                                color: white;
                                &:hover {
                                    color: @theme-primary;
                                }
                            }
                            .fa {
                                margin-right: @padding-large-base;
                            }
                            &.divider {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
    .navbar-nav > .active > a {
        //border-radius: 0;
        color: white;
        background-color: transparent;
    }
    .navbar-nav > .active > a:hover,
    .navbar-nav > .active > a:focus {
        background-color: transparent;
    }
    .show-affix {
        display: none;
        a {
            color: @theme-primary !important;
        }
    }
}

@media (min-width: @screen-sm) {
    .navbar-custom {
        background: rgba(0, 23, 55, 0);
        padding: @padding-large-base 0;
        -webkit-transition: padding 0.3s;
        -moz-transition: padding 0.3s;
        transition: padding 0.3s;
        border: none;
        .navbar-brand {
            font-size: 2em;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            transition: all 0.3s;
        }
        .navbar-nav > .active > a {
            border-radius: 3px;
        }
    }
}

// Navbar Change on Scroll
@media (min-width: @screen-sm) {
    .navbar-custom.affix {
        background: rgba(0, 23, 55, 1);
        -webkit-transition: background 250ms ease-in;
        -moz-transition: background 250ms ease-in;
        -o-transition: background 250ms ease-in;
        -ms-transition: background 250ms ease-in;
        transition: background 250ms ease-in; //padding: @padding-large-base 40px;
        .navbar-brand {
            font-size: 1.5em;
        }
        .show-affix {
            display: inherit;
        }
    }
}

.sub-nav-content {
    position: relative;
    padding-top: @navbar-height;
}

.sub-nav {
    .navbar-header {
        float: left;
    }
    .navbar-nav > li,
    .navbar-nav {
        float: left !important;
    }
    .navbar-nav.navbar-right:last-child {
        margin-right: -15px !important;
    }
    .navbar-right {
        float: right !important;
        padding-right: 0;
    }
    .navbar-form {
        width: auto;
        border: 0;
        margin-left: 0;
        margin-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
    background-color: #fff;
    border-radius: 0;
    border-top: 0;
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    &.affix {
        position: fixed;
        width: 100%;
        top: 70px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid #ddd;
        @media (max-width: 767px) {
            top: 54px;
        }
    }
    .navbar-brand {
        .heading-font;
        text-transform: uppercase;
        @media (max-width: 768px) {
            font-size: 14px;
        }
    }
    .search-group {
        position: relative;
        &.active {
            input.form-control {
                width: 300px;
                @media (max-width: 768px) {
                    width: 320px;
                }
            }
            i.fa-times-circle {
                display: inline-block;
                cursor: pointer;
            }
        }
        input.form-control {
            border-radius: 50px;
            padding-left: 2.5em;
            padding-right: 1em;
            width: 100px;
            @media (min-width: 768px) {
                transition: width 0.5s ease;
            }
        }
        i.fa-search {
            position: absolute;
            left: 1em;
            top: @padding-base-vertical + 4px;
        }
        i.fa-times-circle {
            display: none;
            position: absolute;
            right: 1em;
            top: @padding-base-vertical + 4px;
        }
    }
    .dropdown-menu {
        padding: 5px 10px;
        border-radius: 0;
    }
}

header {
    position: relative;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    .background-cover;
    min-height: 300px;
    text-align: center;
    color: white;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: @theme-secondary;
        opacity: 0.8;
    }
    &.empty {
        min-height: 200px;
    }
    &.empty + .container {
        margin-top: -100px;
        position: relative;
    }
    .fancybox {
        margin-top: 100px;
        margin-bottom: 70px;
    }
    .intro-text {
        padding-top: 100px;
        padding-bottom: 70px;
        .intro-lead-in {
            .body-font;
            font-style: italic;
            font-weight: 300;
            font-size: 18px;
            line-height: 18px;
            margin-bottom: 40px;
            padding: 0 100px;
        }
        .intro-heading {
            .heading-font;
            padding: 0 100px;
            font-weight: 300;
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 25px;
        }
        .text-link {
            font-size: 12px;
            display: block;
            color: #fff;
            text-decoration: underline;
            margin-top: 20px;
            opacity: 0.8;
            &:hover {
                opacity: 1;
            }
        }
    }
    &.solutions-header {
        &:before {
            display: none;
        }
    }
}

@media (min-width: 768px) {
    header {
        .intro-text {
            padding-top: 160px;
            padding-bottom: 120px;
            .intro-lead-in {
                font-size: 22px;
                line-height: 28px;
                margin-bottom: 50px;
            }
            .intro-heading {
                font-size: 40px;
                line-height: 50px;
                margin-bottom: 50px;
            }
        }
    }
}

@media (min-width: 992px) {
    header {
        .intro-text {
            padding-top: 250px;
            padding-bottom: 200px;
            .intro-lead-in {
                font-size: 28px;
                line-height: 30px;
                margin-bottom: 50px;
            }
            .intro-heading {
                font-size: 60px;
                line-height: 60px;
                margin-bottom: 50px;
            }
        }
    }
}

// Global Section Styles
section {
    padding: 15px 0;
    .container > h2 {
        margin: 0 0 30px 0;
    }
    /*
    &:first-of-type {
        margin-top: -50px;
        padding-top: 0;
        @media(max-width:768px) {
          margin-top: -20px;
        }
    }
    */
}

@media (min-width: 768px) {
    section {
        padding: 15px 0;
    }
}

// Contact Section
section#register {
    .form-group {
        margin-bottom: 25px;
        input,
        textarea,
        select {
            padding: 20px;
            background-color: #fbfbfb;
        }
        input.form-control {
            height: auto;
        }
        select.form-control {
            height: 62px;
        }
        textarea.form-control {
            height: 236px;
        }
    }
    .form-control {
        &:focus {
            border-color: @theme-primary;
            box-shadow: none;
        }
        &:placeholder {
            color: @gray-mid-light;
        }
        &::-webkit-input-placeholder {
            color: @gray-mid-light;
        }
        &:-webkit-input-placeholder {
            color: @gray-mid-light;
        }
        &:-moz-placeholder {
            /* Firefox 18- */
            color: @gray-mid-light;
        }
        &::-moz-placeholder {
            /* Firefox 19+ */
            color: @gray-mid-light;
        }
        &:-ms-input-placeholder {
            color: @gray-mid-light;
        }
    }
    .text-danger {
        color: @theme-danger;
    }
}

// Footer
footer {
    padding: 25px 0;
    span.copyright {
        line-height: 40px;
        .heading-font;
        text-transform: none;
    }
    ul.quicklinks {
        margin-bottom: 0;
        line-height: 40px;
        .heading-font;
        text-transform: none;
    }
}

// Social Buttons
.share-buttons {
    margin-top: 15px;
    &.share-buttons-solution {
        margin-top: 5px;
    }
    a {
        display: inline-block;
        text-align: center;
        width: 25px;
        height: 25px;
        font-size: 15px;
        line-height: 25px;
        border: 1px solid @gray-mid;
        color: @gray-mid;
        border-radius: 50px;
        outline: none;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
        margin-right: 5px;
        &:last-child {
            margin-right: 0;
        }
        &:hover,
        &:focus,
        &:active {
            background-color: @gray-mid;
            color: #fff;
        }
    }
}

.btn:focus,
.btn:active,
.btn.active,
.btn:active:focus {
    outline: none;
}

section#solutions {
    .container {
        overflow: visible;
        position: relative;
    }
    .swiper-container {
        padding-bottom: 5px;
    }
    .swiper-slide .box {
        min-height: 360px;
        @media (max-width: 992px) {
            min-height: 360px;
        }
        @media (max-width: 768px) {
            min-height: 360px;
        }
    }
    .swiper-button {
        transition: all 0.3s ease;
        font-size: 20px;
        color: @gray;
        margin-top: 0;
        background-image: none;
        .fa-stack {
            background-color: @gray-lighter;
        }
        .fa-stack-2x {
            color: @gray;
        }
        .fa-stack-1x {
            color: #fff;
        }
        &:hover {
            .fa-stack-2x {
                color: lighten(@gray, 5%);
            }
        }
        @media (max-width: 1279px) {
            top: -7px;
            .fa-stack-2x,
            .fa-stack-2x:active {
                color: transparent !important;
            }
            .fa-stack-1x {
                color: @gray;
            }
        }
        @media (max-width: 768px) {
            top: -10px;
        }
    }
    .swiper-button-prev {
        @media (max-width: 1279px) {
            left: 12px;
        }
        .fa-stack-1x {
            margin-left: -2px;
        }
        left: -40px;
    }
    .swiper-button-next {
        @media (max-width: 1279px) {
            right: 22px;
        }
        .fa-stack-1x {
            margin-left: 2px;
        }
        right: -30px;
    }
}

.solution-modal {
    .modal-dialog {
        margin: 0;
        height: 100%;
        width: auto;
    }
    .modal-content {
        border-radius: 0;
        background-clip: border-box;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: none;
        min-height: 100%;
        padding: 100px 0;
        background-color: @gray-lighter;
        h2 {
            margin-bottom: 15px;
            font-size: 3em;
        }
        p {
            margin-bottom: 30px;
        }
        p.item-intro {
            text-align: center;
            margin: 20px 0 30px;
            .serif-font;
            font-style: italic;
            font-size: 16px;
        }
        ul.list-inline {
            margin-bottom: 30px;
            margin-top: 0;
        }
        img {
            margin-bottom: 30px;
        }
        @media (max-width: 768px) {
            padding: 40px 0;
            .modal-body {
                padding: 0;
            }
            h2 {
                font-size: 22px;
            }
        }
    }
    .close-modal {
        position: absolute;
        width: 75px;
        height: 75px;
        background-color: transparent;
        top: 25px;
        right: 25px;
        cursor: pointer;
        &:hover {
            opacity: 0.3;
        }
        .lr {
            height: 75px;
            width: 1px;
            margin-left: 35px;
            background-color: @gray-darker;
            transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            /* IE 9 */
            -webkit-transform: rotate(45deg);
            /* Safari and Chrome */
            z-index: 1051;
            .rl {
                height: 75px;
                width: 1px;
                background-color: @gray-darker;
                transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                /* IE 9 */
                -webkit-transform: rotate(90deg);
                /* Safari and Chrome */
                z-index: 1052;
            }
        }
        @media (max-width: 768px) {
            width: 40px;
            height: 40px;
            top: 10px;
            right: 10px;
            .lr {
                height: 40px;
                margin-left: 20px;
                .rl {
                    height: 40px;
                }
            }
        }
    }
    .modal-backdrop {
        opacity: 0;
        display: none;
    }
}

// Highlight Color Customization
::-moz-selection {
    text-shadow: none;
    background: @theme-primary;
}

::selection {
    text-shadow: none;
    background: @theme-primary;
}

img::selection {
    background: transparent;
}

img::-moz-selection {
    background: transparent;
}

body {
    -webkit-tap-highlight-color: @theme-primary;
}

.versus {
    font-size: 18px;
    font-weight: 100;
    display: inline-block;
    margin: 0 12px 0 15px;
    padding-top: 3px;
    @media (max-width: 768px) {
        display: block;
        font-size: 12px;
        margin: 10px 0;
    }
}

.search-container {
    min-height: 1200px;
    .filter-list {
        margin-top: 20px;
        background-color: @gray-dark;
        color: #fff;
        padding: 5px 20px;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
        text-align: center;
        ul {
            margin: 0;
            li {
                min-width: 80px;
                padding-right: 20px;
                @media (max-width: 1200px) {
                    padding-right: 10px;
                }
                label {
                    font-size: 14px;
                    font-weight: 200;
                }
            }
        }
    }
    &.extra-margin {
        margin-top: 71px;
    }
}

.search-header {
    margin: 100px auto 100px auto;
    padding: 0 20px;
    h1 {
        color: #fff;
        font-weight: 200;
        margin: 0 0 30px 0;
    }
    .search-group {
        position: relative;
        max-width: 400px;
        margin: 0 auto;
        transition: all 0.3s ease-in-out;
        opacity: 0.8;
        i.fa-times-circle {
            display: none;
        }
        &.active {
            width: 100%;
            max-width: 700px;
            opacity: 1;
            i.fa-times-circle {
                display: inline-block;
                cursor: pointer;
            }
        }
        input.form-control {
            border: 0;
            border-radius: 50px;
            padding-left: 50px;
            padding-right: 50px;
        }
        i.fa-search {
            font-size: 18px;
            color: @gray-mid;
            position: absolute;
            left: 18px;
            top: 14px;
        }
        i.fa-times-circle {
            font-size: 18px;
            color: #555;
            position: absolute;
            right: 18px;
            top: 14px;
        }
    }
}

.solution-detail {
    margin-top: -220px;
    position: relative;
    .category {
        font-weight: 600;
    }
    h1 {
        height: 104px;
        margin: 20px 0; //font-weight: 200;
        font-size: 48px;
        letter-spacing: -1px;
    }
    h2 {
        text-align: left;
        margin: 20px 0;
        &:before {
            display: none;
        }
    }
    img {
        max-width: 100%;
        margin-bottom: 20px;
        &.main-image {
            width: 100%;
            margin: 20px 0;
        }
        &.alignleft {
            float: left;
            margin-right: 1.5em;
        }
    }
    p.intro {
        color: @gray-mid;
        .serif-font;
        font-style: italic;
        font-size: 16px;
    }
    @media (max-width: 768px) {
        h1 {
            font-size: 30px;
        }
    }
    @media (max-width: 400px) {
        h1 {
            font-size: 24px;
        }
    }
    .alert-info {
        margin-top: 20px;
    }
}

.links {
    background-color: @gray-dark;
    color: #fff;
    margin: 30px -30px -30px -30px;
    padding: 10px 30px;
    a {
        color: #fff;
        margin-right: 20px;
    }
    .article-detail &,
    .solution-detail & {
        text-align: center;
        margin: 20px -30px 0 -30px;
        background-color: #fff;
        font-size: 24px;
        a {
            color: @gray-dark;
            margin-right: 30px;
        }
    }
}

.no-results {
    padding: 20px;
    font-size: 16px;
    color: @gray-mid;
    margin-top: 40px;
    text-align: center;
}

input[type='checkbox'] {
    display: none;
}

.checkbox {
    &.input-sm {
        padding: 0;
    }
    label {
        padding-left: 0;
        user-select: none; //line-height: 1em;
    }
}

.table {
    .checkbox {
        margin: 0;
    }
}

input[type='checkbox'] ~ .fa {
    margin-right: 10px;
    font-size: 16px; //width: 30px;
    display: inline-block;
    margin-top: 2px;
    color: @gray-mid;
}

input[type='checkbox'] ~ .fa-check-square,
input[type='checkbox'] ~ .fa-check-square-o {
    display: none;
}

input[type='checkbox']:checked ~ .fa-check-square,
input[type='checkbox']:checked ~ .fa-check-square-o {
    display: inline-block;
    color: @theme-primary;
    margin-right: 9px;
}

input[type='checkbox']:checked ~ .fa-square-o,
input[type='checkbox']:checked ~ .fa-square {
    display: none;
}

.input-group-addon {
    input[type='checkbox'] ~ .fa {
        margin-top: 0;
    }
}

.form-regular {
    .form-group {
        margin-bottom: 25px;
        input,
        textarea,
        select {
            padding: 20px;
            border: 1px solid #fff;
            background-color: @gray-lighter; //box-shadow: inset 0 3px 0 0 rgba(0,0,0,0.1);
            line-height: @line-height-base;
            &[disabled] {
                color: lighten(@gray-light, '20');
            }
        }
        .form-control-static {
            padding: 0 20px;
        }
        input.form-control {
            height: auto;
        }
        select.form-control {
            height: 62px;
        }
        textarea.form-control {
            height: 236px;
        }
        &.has-error {
            input,
            textarea,
            select {
                border-color: @brand-danger;
            }
        }
    }
    .form-control {
        &:focus {
            border-color: @theme-primary;
            box-shadow: none;
        }
        &:placeholder {
            color: @gray-mid-light;
        }
        &::-webkit-input-placeholder {
            color: @gray-mid-light;
        }
        &:-webkit-input-placeholder {
            color: @gray-mid-light;
        }
        &:-moz-placeholder {
            /* Firefox 18- */
            color: @gray-mid-light;
        }
        &::-moz-placeholder {
            /* Firefox 19+ */
            color: @gray-mid-light;
        }
        &:-ms-input-placeholder {
            color: @gray-mid-light;
        }
    }
    .text-danger {
        color: @theme-danger;
    }
    .checkbox {
        &.input-sm {
            padding: 0;
        }
        label {
            padding-left: 0;
            font-size: 1.1em;
        }
    }
    input[type='checkbox'] ~ .fa {
        font-size: 20px;
        display: inline-block;
        margin-left: 1px;
        margin-right: 10px;
        color: @gray-mid;
    }
    input[type='checkbox'] ~ .fa-check-square {
        display: none;
    }
    input[type='checkbox']:checked ~ .fa-check-square {
        display: inline-block;
        color: @theme-primary;
        margin-right: 9px;
    }
    input[type='checkbox']:checked ~ .fa-square-o {
        display: none;
    }
    &.no-validation {
        .has-error {
            input,
            textarea,
            select {
                border-color: #fff;
            }
            .help-block {
                display: none;
            }
        }
    }
}

.nav-tabs li a {
    text-decoration: none !important;
}

.tab-pane {
    padding-top: 30px;
}

div.alert {
    border-radius: 0;
    border: 0;
    font-size: 1.2em;
}

.btn.btn-primary {
    border: 1px solid @theme-primary;
    color: @theme-primary;
    background: transparent;
    font-family: 'Roboto';
    font-weight: 300;
    &:hover {
        border-color: darken(@theme-primary, 10%);
        color: darken(@theme-primary, 10%);
    }
    &.btn-xl {
        box-shadow: none;
        color: #fff;
        background: @theme-primary;
        border: 0;
    }
}

.btn.btn-default {
    border: 1px solid #888;
    color: #888;
    background: transparent;
    font-family: 'Roboto';
    font-weight: 300;
    &:hover {
        border-color: #444;
        color: #444;
    }
}

.show-all {
    margin-top: 40px;
    text-align: center;
}

figcaption {
    font-size: 12px;
    margin: -20px 0 10px 0;
    color: #888;
    font-style: italic;
}

table.user-solutions {
    .revoked {
        td {
            text-decoration: line-through;
        }
    }
}

.row.loading {
    min-height: 242px;
}

.sk-fading-circle {
    display: none;
    margin: 100px auto;
    width: 40px;
    height: 40px;
    position: relative;
}

.sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
    0%,
    39%,
    100% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
}

@keyframes sk-circleFadeDelay {
    0%,
    39%,
    100% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
}

.campaign-article-container {
    max-height: 500px;
    overflow: auto;
    margin-top: 5px;
    .campaign-article {
        font-weight: bold;
        padding: 15px;
        cursor: pointer;
        display: block;
        position: relative;
        i.fa {
            display: none;
            position: absolute;
            left: 0;
            top: 17px;
        }
        &:hover {
            i.fa {
                display: inline-block;
            }
        }
    }
}

.form-campaign {
    margin-bottom: 30px;
}

.modal {
    &.modal-show {
        display: block;
    }
}

.login-form {
    max-width: 700px;
    margin: 0 auto;
    .form-group {
        margin-bottom: 0;
    }
}

.file-input {
    .file-input-loader {
        position: relative;
        display: inline-block;
        cursor: pointer;
        .btn {
            position: relative;
            z-index: 0;
            .btn-progress {
                position: absolute;
                height: 100%;
                width: 0%;
                transition: all 50ms ease;
                z-index: -1;
                left: 0;
                top: 0;
            }
            &.btn-empty {
                .button-variant(@theme-primary;
                transparent;
                @theme-primary);
            }
            &.btn-ready {
                .button-variant(@brand-warning;
                transparent;
                @brand-warning;
                );
                .btn-progress {
                    background-color: @brand-success;
                }
            }
            &.btn-done {
                .button-variant(white;
                @brand-success;
                @brand-success);
            }
        }
        input {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }
    .file-input-big-progress {
        display: block;
        position: fixed;
        width: 100%;
        top: 45%;
        text-align: center;
        .heading-font;
        font-weight: 600;
        color: @theme-primary;
        font-size: @font-size-h1;
        left: 0;
        z-index: 5;
    }
}

.supplier-logo {
    max-width: 250px;
    max-height: 250px;
}
